<template>
  <div>
    <div class="black pt-10 pb-10">
      <v-container>
        <h1 class="white--text text-center">Register your Product</h1>
      </v-container>
    </div>
    <div class="grey lighten-2 pt-10 pb-10">
      <v-container class="black--text">
        <p class="text-center">
          By registering, you'll receive information regarding new firmware upgrades, product trade-in programs and
          general information to help you get the most out of your new gear.
        </p>
      </v-container>
    </div>
    <div class="white pt-10 pb-10">
      <v-container class="text-center" style="max-width: 1000px">
        <v-dialog v-model="examplesDialog" max-width="800" persistent>
          <v-card>
            <v-card-title>{{ $i18n.translate("Examples") }}</v-card-title>
            <v-card-text class="text-center"><img :src="dialogImage" alt="Sample Barcodes" width="600" /> </v-card-text>
            <v-card-actions
              ><v-spacer></v-spacer
              ><v-btn class="primary" @click="examplesDialog = false">{{
                $i18n.translate("Close")
              }}</v-btn></v-card-actions
            >
          </v-card>
        </v-dialog>

        <ApiError :errors="errors" class="text-left"></ApiError>

        <span id="form-top"></span>
        <v-container v-if="!productRegistrationSubmitted">
          <v-stepper v-model="step" vertical id="stepper">
            <v-stepper-step :editable="step > 1" step="1" style="text-align: left !important">{{
              $i18n.translate("Details")
            }}</v-stepper-step>
            <v-stepper-content step="1" id="step-1">
              <v-form ref="step1Form" @submit.prevent="onNextStep()" v-if="!loading" v-model="valid.step1">
                <v-container>
                  <v-col cols="12">
                    <v-subheader class="pl-0">
                      <h3>End User Information</h3>
                    </v-subheader>
                    <v-divider></v-divider>
                  </v-col>
                  <v-row class="flex-wrap">
                    <v-col cols="12" md="6">
                      <v-text-field
                        class="required"
                        v-model="user.firstName"
                        :label="$i18n.translate('First Name')"
                        :rules="fieldRules.required"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        class="required"
                        v-model="user.lastName"
                        :label="$i18n.translate('Last Name')"
                        :rules="[v => !!v || 'Last Name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        class="required"
                        v-model="participant.organizationName"
                        :label="$i18n.translate('Organization Name')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <EmailField
                        v-model="user.email"
                        v-on="$listeners"
                        label="Email Address"
                        class="required"
                        :rules="fieldRules.required"
                      ></EmailField>
                    </v-col>
                    <v-col cols="12" md="6">
                      <PhoneNumberField
                        v-model="participant.phoneNumber1"
                        label="Phone Number"
                        class="required"
                        :rules="fieldRules.required"
                        :country="selectedLocale.countryType"
                      ></PhoneNumberField>
                    </v-col>
                    <v-col cols="12" md="6">
                      <CountryField
                        v-model="participant.country"
                        v-on="$listeners"
                        label="Country"
                        class="required"
                        :rules="fieldRules.required"
                        :available="[selectedLocale.countryType]"
                        :country="selectedLocale.countryType"
                      ></CountryField>
                    </v-col>
                  </v-row>
                  <v-col cols="12">
                    <v-subheader class="pl-0">
                      <h3>Sony Dealer Information</h3>
                    </v-subheader>
                    <v-divider></v-divider>
                  </v-col>
                  <OrganizationField
                    :searchFilterJson="{
                      organizationTypeKeys: ['Z1', 'Z4', 'ZB', 'ZD', 'ZT'],
                      programKey: 'SONY-CPR',
                      programGroupKey: 'SONY'
                    }"
                    source="SONY SAP ov083b"
                    :showBottomMessage="true"
                    :publicField="true"
                    :showCityRegionPostalCode="true"
                    :rules="fieldRules.required"
                    @input="step == 1 ? updateSonyDealer($event) : null"
                  >
                  </OrganizationField>
                </v-container>

                <div class="mb-5">
                  <v-btn color="primary" class="mx-1" @click="onNextStep()" :disabled="!valid.step1">
                    {{ $i18n.translate("Continue") }}
                  </v-btn>
                </div>
              </v-form>
            </v-stepper-content>

            <v-stepper-step :editable="step > 2 || valid.step1" step="2" style="text-align: left !important"
              >Add Products</v-stepper-step
            >
            <v-stepper-content step="2" id="step-2">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" class="text-left">
                    <h4>Manually Select Products</h4>
                    <p class="body-2">
                      Select products from the drop-down and begin entering serial numbers and purchase dates.
                    </p>
                    <ProductsField
                      v-model="selectedProducts"
                      ref="productsSelect"
                      programGroupKey="SONY"
                      :publicField="true"
                      :isMenuActive="true"
                    />
                    <div class="d-flex align-center">
                      <v-btn
                        color="primary"
                        small
                        @click="
                          onAddProduct();
                          $refs['productsSelect'].isMenuActive = false;
                        "
                        >{{ $i18n.translate("Add") }}</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider />
                </v-row>
                <v-form ref="step2Form" @submit.prevent="onNextStep()" v-if="!loading" v-model="valid.step2">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="productRegistrationHeaders"
                        :items="productRegistration"
                        disable-pagination
                        hide-default-footer
                        no-data-text="No Products Selected"
                        no-results-text="No Products Selected"
                      >
                        <template v-slot:item.serialNumber="{ item }">
                          <v-container>
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-text-field
                                  prefix="S01 -"
                                  :label="$i18n.translate('Serial Number')"
                                  v-model="item.serialNumber"
                                  @keydown="keyHandler($event)"
                                  @blur="formatSerialNumber(item)"
                                  :rules="[requireSerialNumber(item), checkDuplicatedSerialNumber(item)]"
                                >
                                  <template v-slot:append>
                                    <v-tooltip bottom max-width="500">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" @click="() => {}"
                                          >mdi-information-outline</v-icon
                                        >
                                      </template>
                                      <span>
                                        {{
                                          $i18n.translate(
                                            "Serial Numbers can be found on the outside of the original product packaging. Your sales invoice may also contain this detail. The supporting documentation that is required during the Registration process must contain the model and serial number information in order to validate your purchase."
                                          )
                                        }}
                                      </span>
                                    </v-tooltip>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        <template v-slot:item.purchaseDate="{ item }">
                          <DateTimePickerField
                            v-model="item.purchaseDate"
                            :rules="getPurchaseDateRules()"
                            format="MM/DD/YYYY"
                            defaultTime="12:00"
                            onlyDate
                          ></DateTimePickerField>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div style="white-space: nowrap">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-1" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                                  >mdi-delete</v-icon
                                >
                              </template>
                              <span>{{ $i18n.translate("Delete Product") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-1" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)"
                                  >mdi-content-duplicate</v-icon
                                >
                              </template>
                              <span>{{ $i18n.translate("Duplicate Product") }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mx-1"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="$refs['productsSelect'].isMenuActive = true"
                                  >mdi-plus-circle-outline</v-icon
                                >
                              </template>
                              <span>{{ $i18n.translate("Add new product") }}</span>
                            </v-tooltip>
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>

              <div class="mb-5">
                <v-btn color="primary" class="mx-1" @click="onNextStep()" :disabled="!validProductRegistration">
                  {{ $i18n.translate("Continue") }}
                </v-btn>
                <v-btn class="mx-1" @click="onPrevStep" text>{{ $i18n.translate("Back") }}</v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-step
              :editable="step > 3 || (valid.step1 && validProductRegistration)"
              :step="3"
              style="text-align: left !important"
            >
              {{ $i18n.translate("Review Product Details") }}
            </v-stepper-step>
            <v-stepper-content id="step-3" :step="3">
              <v-form @submit.prevent="onSubmit" v-model="valid.step3">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Review Product Details") }}
                        </v-card-title>
                        <v-card-text>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("First Name") }}</th>
                                  <td width="70%" class="text-left">{{ user.firstName }}</td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Last Name") }}</th>
                                  <td width="70%" class="text-left">{{ user.lastName }}</td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Organization Name") }}</th>
                                  <td width="70%" class="text-left">{{ participant.organizationName }}</td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Email Address") }}</th>
                                  <td width="70%" class="text-left">{{ user.email }}</td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Phone Number") }}</th>
                                  <td width="70%" class="text-left">{{ participant.phoneNumber1 }}</td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Country") }}</th>
                                  <td width="70%" class="text-left">
                                    {{ participant && participant.country ? participant.country.name : "" }}
                                  </td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Sony Dealer Name") }}</th>
                                  <td width="70%" class="text-left">{{ sonyDealer.name }}</td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Sony Dealer Address 1") }}</th>
                                  <td width="70%" class="text-left">
                                    {{ sonyDealer.address1 }}
                                  </td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Sony Dealer Address 2") }}</th>
                                  <td width="70%" class="text-left">
                                    {{ sonyDealer.address2 }}
                                  </td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Sony Dealer City") }}</th>
                                  <td width="70%" class="text-left">
                                    {{ sonyDealer.city }}
                                  </td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Sony Dealer State") }}</th>
                                  <td width="70%" class="text-left">
                                    {{
                                      sonyDealer && sonyDealer.region
                                        ? sonyDealer.region.name + " - " + sonyDealer.region.description
                                        : ""
                                    }}
                                  </td>
                                </tr>

                                <tr>
                                  <th width="30%" class="text-right">{{ $i18n.translate("Sony Dealer Zip Code") }}</th>
                                  <td width="70%" class="text-left">
                                    {{ sonyDealer.postalCode }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="productRegistration && productRegistration.length > 0">
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Products") }}
                        </v-card-title>
                        <v-card-text>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr v-for="(productRegistration, i) in productRegistration" :key="i + '-products'">
                                  <th width="30%" class="text-right">
                                    {{ productRegistration.product.productKey }}
                                  </th>
                                  <td width="70%" class="text-left">
                                    <span>
                                      {{ $i18n.translate("Serial Number") }}: {{ productRegistration.serialNumber }}
                                    </span>
                                    -
                                    <span> {{ $i18n.translate("Purchase Date") }}: {{ showPurchaseDate() }} </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="30%" class="text-right">Total Products Registered</th>
                                  <td width="70%" class="text-left">
                                    <span>{{ totalProductsRegistered }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-title primary-title>
                          {{ $i18n.translate("Sony email communications") }}
                        </v-card-title>
                        <v-card-text v-if="selectedLocale.languageType.name == 'fr'">
                          <p>
                            <v-checkbox
                              label="Je souhaite recevoir des communications par e-mail de Sony Imaging Products &amp; Solutions - Americas"
                              v-model="optIn"
                            ></v-checkbox>

                            En cliquant sur 'Soumettre', je confirme que j'accepte le Sony Electronics
                            <a
                              href="https://products.sel.sony.com/SEL/legal/privacy.html"
                              target="_blank"
                              rel="noreferrer"
                              >Politique de confidentialité </a
                            >et certifie que je suis un résident des États-Unis. Vous pouvez
                            <a
                              href="https://m.info.pro.sony.com/webApp/SonyPSAUnsubscribe_01"
                              target="_blank"
                              rel="noreferrer"
                              >se désinscrire</a
                            >
                            à tout moment.
                          </p>
                        </v-card-text>
                        <v-card-text v-else>
                          <p>
                            <v-checkbox
                              label="I'd like to receive Email communications from Sony Imaging Products &amp; Solutions - Americas."
                              v-model="optIn"
                            ></v-checkbox>

                            By clicking 'Submit', I confirm that I agree to the Sony Electronics
                            <a
                              href="https://products.sel.sony.com/SEL/legal/privacy.html"
                              target="_blank"
                              rel="noreferrer"
                              >Privacy Policy </a
                            >and certify that I am a U.S. resident. You may
                            <a
                              href="https://m.info.pro.sony.com/webApp/SonyPSAUnsubscribe_01"
                              target="_blank"
                              rel="noreferrer"
                              >Unsubscribe</a
                            >
                            at any time.
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>

                <div class="mb-5">
                  <v-btn color="primary" class="mx-1" :disabled="!valid.step3" :loading="loading" @click="onSubmit()">
                    {{ $i18n.translate("Submit") }} </v-btn
                  ><br />
                  <GoogleReCaptcha></GoogleReCaptcha>
                  <v-btn class="mx-1" @click="onPrevStep" v-if="!loading" text>{{ $i18n.translate("Back") }}</v-btn>
                </div>
              </v-form>
            </v-stepper-content>
          </v-stepper>
        </v-container>
        <v-container v-else class="text-center">
          <v-icon x-large color="green" id="claim-submitted">mdi-check-circle-outline</v-icon>
          <h3 class="ma-5">{{ $i18n.translate("Registration Submitted Successfully") }}</h3>
          <br />
          <p class="mb-0">{{ $i18n.translate("Registration Number") }}</p>
          <h3>{{ productRegistrationNumber }}</h3>
          <p class="text-caption mt-10" v-if="selectedLocale.languageType.name == 'fr'">
            Avez-vous des questions? N'hésitez pas à nous envoyer un e-mail à<a
              :href="'mailto:' + selectedProgram.programEmail"
              >{{ selectedProgram.programEmail }}</a
            >, ou appelez simplement {{ selectedProgram.programPhone }}. Notre équipe de service à la clientèle est
            disponible entre 8h30 et 17h00 (ct) du lundi au vendredi pour vous aider.
          </p>
          <p class="text-caption mt-10" v-else>
            Have any questions? Don't hesitate to email us at
            <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a
            >, or simply call {{ selectedProgram.programPhone }}. Our customer care team is available between 8:30am and
            5:00pm (ct) Monday through Friday to assist.
          </p>
        </v-container>
      </v-container>
    </div>
  </div>
</template>

<script>
import GoogleReCaptcha from "@/gapp-components/components/display/GoogleReCaptcha.vue";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import ProductsField from "@/gapp-components/components/fields/ProductsField.vue";
import OrganizationField from "@/gapp-components/components/fields/OrganizationField.vue";
import CountryField from "@/gapp-components/components/fields/CountryField.vue";
import PhoneNumberField from "@/gapp-components/components/fields/PhoneNumberField.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    ApiError,
    EmailField,
    PhoneNumberField,
    CountryField,
    OrganizationField,
    ProductsField,
    DateTimePickerField,
    GoogleReCaptcha
  },
  name: "productRegistration",
  metaInfo: {
    title: "Product Registration"
  },
  data: () => ({
    fileToUpload: null,
    step: 1,
    valid: {
      step1: false,
      step2: false,
      step3: false
    },
    fieldRules: {
      required: [v => !!v || "Field is required"],
      participant: [v => !!v || "Participant is required"]
    },
    loading: false,
    errors: {},
    serialNumberRegex: "",
    mask: "!S01-#######",
    tokens: {
      "#": { pattern: /\d/ },
      X: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      S: { pattern: /[a-zA-Z]/ },
      A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
      "!": { escape: true },
      W: { pattern: /^S01-/ }
    },

    participant: {},
    user: {},
    sonyDealer: {},
    optIn: true,
    fieldsHidden: true,
    selectedProducts: [],
    productRegistration: [],
    productRegistrationNumber: "",
    productRegistrationHeaders: [
      {
        value: "product.productKey",
        text: "Model",
        align: "left"
      },
      {
        value: "serialNumber",
        text: "Serial Number",
        sortable: false
      },
      {
        value: "purchaseDate",
        text: "Date of Purchase",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "center",
        sortable: false
      }
    ],

    productRegistrationSubmitted: false,

    form: {},

    q1: false,
    saveEndUserForLater: false,
    selectedEndUser: null,

    examplesDialog: false,
    dialogImage: require("@/assets/sample-barcodes.png")
  }),
  created() {
    this.fetchData();
    if (this.$gtag && this.$gtag.event) {
      this.$gtag.event("access", { page: "eurProductRegistration" });
    }
  },
  watch: {
    productRegistration: {
      handler() {
        this.$refs.step2Form.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    },
    selectedLocale() {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters([
      "selectedParticipant",
      "selectedProgram",
      "selectedClient",
      "selectedLocale",
      "selectedCountry",
      "selectedFiscalYear"
    ]),
    validProductRegistration() {
      if (!this.productRegistration || this.productRegistration.length == 0) return false;
      return this.valid.step2;
    },
    totalProductsRegistered() {
      let amount = 0;
      for (let cp of this.productRegistration) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    }
  },
  methods: {
    getPurchaseDateRules() {
      return [
        v => !!v || "Date of purchase is required",
        v => {
          if (v && moment(v).isAfter(moment())) {
            return "Date of purchase cannot be in the future";
          }
          return true;
        }
      ];
    },

    validateLine(line, lineNumber) {
      let validationResult = {
        errors: [],
        isValid() {
          return this.errors.length == 0;
        },
        getErrors() {
          return this.errors;
        },
        addError(error) {
          this.errors.push("Line " + lineNumber + ": " + error);
        }
      };

      let lineElements = line.split(",");

      if (!line || line.trim() == "") {
        validationResult.addError("Empty line.");
        return validationResult;
      }

      if (lineElements.length != 4) {
        validationResult.addError("Invalid amount of elements.");
        return validationResult;
      }

      if (lineElements[0].trim() == "") {
        validationResult.addError("Product model can't be empty.");
      } else if (
        this.products.find(pp => {
          return pp.product.productKey.trim() == lineElements[0].trim();
        }) == null
      ) {
        validationResult.addError(lineElements[0].trim() + " is not a valid product model.");
      }

      if (lineElements[1].trim() == "") {
        validationResult.addError("Serial number can't be empty.");
      }

      if (lineElements[2].trim() == "") {
        validationResult.addError("Date of purchase can't be empty.");
      } else if (!moment(lineElements[2].trim()).isValid()) {
        validationResult.addError("Invalid format for date of purchase. YYYY-MM-DD format is required");
      }
      return validationResult;
    },
    onNextStep(specificStep) {
      this.step = parseInt(this.step);

      if (!specificStep) {
        specificStep = this.step + 1;
      } else {
        specificStep = parseInt(specificStep);
      }

      switch (this.step) {
        case 1:
          if (!this.valid.step1) {
            this.$refs.step1Form.validate();
          } else {
            this.step = specificStep;
          }
          break;
        case 2:
          if (!this.valid.step1 || !this.valid.step2) {
            this.$refs.step1Form.validate();
            this.$refs.step2Form.validate();
          } else {
            this.step = specificStep;
          }
          break;
        case 3:
          if (!this.valid.step1 || !this.validProductRegistration) {
            this.$refs.step1Form.validate();
            this.$refs.step2Form.validate();
          } else {
            this.step = specificStep;
          }
          break;
      }
      this.$vuetify.goTo("#form-top");
    },
    onPrevStep() {
      this.step--;
      if (this.step < 1) {
        this.step = 1;
      }
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;

      this.productRegistration.forEach(element => {
        if (
          element.serialNumber &&
          element.product.id == item.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return "Serial number duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.serialNumber) {
        const customRegex = /^\d{7}$/;
        if (customRegex.test(item.serialNumber)) {
          return true;
        } else {
          return "Invalid Serial Number";
        }
      } else {
        return "Serial Number required";
      }
    },
    onAddProduct() {
      let products = [];
      if (this.productRegistration) {
        products = this.productRegistration;
      }

      this.selectedProducts.forEach(product => {
        products = products.concat([{ product: product, serialNumber: "" }]);
      });
      this.productRegistration = products;
      this.selectedProducts = [];
    },
    onDeleteProduct(product) {
      const index = this.productRegistration.indexOf(product);
      this.productRegistration.splice(index, 1);
    },
    onDuplicateProduct(product) {
      let productRegistrationCopy = { ...product };
      this.productRegistration = this.productRegistration.concat(productRegistrationCopy);
    },
    showPurchaseDate() {
      return moment(this.productRegistration.purchaseDate).format("MM/DD/YYYY");
    },
    onSubmit() {
      let url = "/api/productRegistrations/submit";
      this.loading = true;
      this.errors = {};

      url += "/public";
      this.user.username = this.user.email;
      this.participant.user = this.user;
      this.participant.participantType = { participantTypeKey: "950" };
      this.participant.participantStatus = { name: "ENR" };
      this.$set(this.participant, "address", {
        country: this.participant.country
      });
      this.participant.optIn = this.optIn;
      let postForm = {};

      postForm.dealerOrganization = { id: this.sonyDealer.id };

      this.participant.organization = {
        name: this.participant.organizationName
      };
      this.participant.organization.organizationType = {
        organizationTypeKey: "GENERAL"
      };
      postForm.participant = this.participant;

      postForm.productRegistrationItems = this.productRegistration;
      postForm.productRegistrationItems.forEach(pr => {
        if (pr.purchaseDate) {
          pr.purchaseDate = moment(pr.purchaseDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
      });
      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "PRODUCT_REGISTRATION_TEMPLATE"
      };

      /**
          If there are products, use default claim stage which routes to the first "audit" claim stage.
  
          If there are no products, then pass the "unsubmitted" claim stage as the first stage allowing
          Gapp to update the claim based on the uploaded invoice.
        **/

      this.$api
        .postWithCaptcha(url, postForm, null, "productRegistration")
        .then(({ data }) => {
          this.form = data;
          this.productRegistrationNumber = data.productRegistrationKey;
          this.productRegistrationSubmitted = true;
          if (this.$gtag) {
            this.$gtag.event("submit", { page: "eurProductRegistration" });
          }
        })
        .catch(error => {
          this.productRegistrationSubmitted = false;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.$vuetify.goTo(0);
        });
      // }
    },
    fetchData() {
      if (this.selectedParticipant) {
        if (this.selectedParticipant.optIn == undefined) {
          this.participant.optIn = true;
        } else {
          this.participant.optIn = this.selectedParticipant.optIn;
        }
      }
    },
    updateSonyDealer(v) {
      this.sonyDealer = v;
    },
    onCancel() {
      this.$router.push({ name: "eurList" });
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        const parts = item.serialNumber.split("-");
        if (parts.length > 1) {
          const prefix = "S01 -";
          let serialPart = parts[1];
          if (serialPart.length < 7) {
            serialPart = serialPart.padStart(7, "0");
          }
          item.serialNumber = `${prefix}${serialPart}`;
        }
      }
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    }
  }
};
</script>
<style>
.v-menu__content {
  margin-top: 15px;
}
</style>
